@if (leaderboard) {
  <div class="leaderboard-container">
    <div class="title pb-x2">
      {{ leaderboard.titleKey | translate }}
      @if (leaderboard.tooltipKey) {
        <dpa-tooltip [tooltipText]="leaderboard.tooltipKey | translate">
          <cds-icon
            shape="info-circle"
            size="24"
          />
        </dpa-tooltip>
      }
    </div>
    @if (!leaderboard.rows.length) {
      <div class="no-data-label">
        {{ 'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate }}
      </div>
    } @else {
      <div class="leaderboard-col-container">
        @for (col of leaderboard.cols; track col; let i = $index) {
          <div class="leaderboard-col">
            @if (leaderboard.columnTitleKeys) {
              <div class="leaderboard-col-title leaderboard-cell">
                {{ leaderboard.columnTitleKeys[i] | translate }}
              </div>
            }
            @for (cell of col; track cell) {
              <div class="leaderboard-cell">
                @switch (dataUnit[i]) {
                  @case (DATA_UNIT.MILLISECONDS) {
                    <span>
                      <dpa-decorated-value
                        [value]="cell"
                        [valueDecorator]="DATA_DECORATOR.TIME_DURATION"
                      />
                    </span>
                  }
                  @case (DATA_UNIT.SECONDS) {
                    <span>
                      <dpa-decorated-value
                        [value]="cell * 1000"
                        [valueDecorator]="DATA_DECORATOR.TIME_DURATION_IN_SECONDS"
                      />
                    </span>
                  }
                  @case (DATA_UNIT.MINUTES) {
                    <span>
                      <dpa-decorated-value
                        [value]="cell * 60000"
                        [valueDecorator]="DATA_DECORATOR.TIME_DURATION_IN_MINUTES"
                      />
                    </span>
                  }
                  @case (DATA_UNIT.HOURS) {
                    <span>
                      <dpa-decorated-value
                        [value]="cell * 3600000"
                        [valueDecorator]="DATA_DECORATOR.TIME_DURATION_IN_HOURS"
                      />
                    </span>
                  }
                  @case (DATA_UNIT.PERCENT) {
                    <span>
                      <dpa-decorated-value
                        [value]="cell"
                        [valueDecorator]="DATA_DECORATOR.STRING_PERCENT"
                      />
                    </span>
                  }
                  @default {
                    <span>{{ cell?.toLocaleString() }}</span>
                  }
                }
              </div>
            }
          </div>
        }
        @if (showBars) {
          <div class="bar-col">
            @if (leaderboard.columnTitleKeys) {
              <div class="leaderboard-col-title leaderboard-cell"></div>
            }
            @for (row of leaderboard.rows; track row) {
              <div>
                <dpa-ratio-indicator
                  [ratios]="getRatiosByColorSet(row)"
                  [total]="leaderboard.maxRowTotal"
                />
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
} @else {
  <div class="spinner-container">
    <div class="spinner spinner-inline"></div>
  </div>
}
