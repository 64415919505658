<div
  [class.compact-gap]="isCompactGap"
  class="star-rating-container"
>
  @for (count of range; track count) {
    <cds-icon
      [class.clickable]="!isReadOnly"
      [attr.size]="iconSize"
      [attr.solid]="count <= rating"
      (click)="onStarClick(count)"
      shape="star"
    />
  }
</div>
